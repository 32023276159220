<template>
  <v-container>
    <div class="text-center">
      <h1>Hantering</h1>
      <v-card>
        <v-list>
          <v-list-item
            v-for="booking in bookings"
            :key="booking.id"
          >
            <v-list-item-avatar>
              <v-icon v-if="!booking.cancelled">mdi-duck</v-icon>
              <v-icon v-if="booking.cancelled">mdi-close</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="booking.name"></v-list-item-title>

              <v-list-item-subtitle>{{ booking.start_date }} ~ {{ booking.end_date }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn v-if="!booking.cancelled" @click="cancel_booking(booking.id)" icon>
                <v-icon color="grey lighten-1">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'
import { getAuthHeader } from '../../utils/tools'

export default {
  name: 'Admin',
  components: {
  },
  data: () => ({
    bookings: []
  }),
  mounted: function () {
    axios.get('/api/uthyrning/all', getAuthHeader())
      .then((result) => {
        this.bookings = result.data
      })
  },
  methods: {
    cancel_booking (id) {
      const booking = this.bookings[id]
      if (confirm(`Är du säker på att du vill ta bort uthyrningen "${booking.name}"?`)) {
        axios.put('/api/uthyrning/remove/' + id, 0, getAuthHeader()) // 0 is dummy value, id sent as param in url
          .then((result) => {
            console.log(result)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }
}
</script>
